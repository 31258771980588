import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';

const PrivacyPolicy = ({ location }) => {
  const pageTitle = 'Privacy Policy';
  return (
    <Layout pageTitle={pageTitle}>
      <Seo index={false} follow={false} pathname={location.pathname} />
      <Article>
        <h1>{pageTitle}</h1>
        <p>Effective date: September 14, 2022</p>

        <p>
          Rad Works ("us", "we", or "our") operates the https://raddevon.com
          website (the "Service").
        </p>

        <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, terms used in this Privacy Policy have the same meanings as in
          our Terms and Conditions, accessible from https://raddevon.com
        </p>

        <h2>Information Collection And Use</h2>

        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>

        <h3>Types of Data Collected</h3>

        <h4>Personal Data</h4>

        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact
          ("Personal Data"). Personally identifiable information may include,
          but is not limited to:
        </p>

        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
        </ul>

        <p>
          Our email service provider Mailerlite may collect your IP address to
          verify your subscription. Please check{' '}
          <a href="https://www.mailerlite.com/legal/privacy-policy">
            {' '}
            their privacy policy
          </a>
          .
        </p>

        <h4>Usage Data</h4>

        <p>
          We do not collect any usage data. Our email service provider
          Mailerlite may collect data when you access a page with an embedded
          sign-up form. Please check{' '}
          <a href="https://www.mailerlite.com/legal/privacy-policy">
            {' '}
            their privacy policy
          </a>
          .
        </p>

        <h4>Tracking &amp; Cookies Data</h4>
        <p>
          We do not use cookies to track you on this or any other web site.
          Newsletter sign-up form are embedded from our email service provider,
          Mailerlite. Please check{' '}
          <a href="https://www.mailerlite.com/legal/privacy-policy">
            {' '}
            their privacy policy.
          </a>
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </p>

        <h2>Use of Data</h2>

        <p>
          Rad Works uses only data explicitly provided by you for various
          purposes:
        </p>
        <ul>
          <li>To email you information you requested</li>
          <li>To notify you about changes</li>
          <li>To provide customer care and support</li>
        </ul>

        <h2>Transfer Of Data</h2>
        <p>
          Data is collected directly by our email service provider Mailerlite.
          Learn more about what data they collect and how they use it through{' '}
          <a href="https://www.mailerlite.com/legal">
            their legal information page
          </a>
          .
        </p>
        <p>
          We reserve the right to change providers, in which case your data will
          be transfered to the new provider and this privacy policy will be
          updated. Your data will not be transfered for any other purpose.
        </p>

        <h2>Disclosure Of Data</h2>

        <h3>Legal Requirements</h3>
        <p>
          Rad Works may disclose your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Rad Works</li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            To protect the personal safety of users of the Service or the public
          </li>
          <li>To protect against legal liability</li>
        </ul>

        <h2>Security Of Data</h2>
        <p>
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>

        <h2>Service Providers</h2>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), to provide the Service on our behalf,
          to perform Service-related services or to assist us in analyzing how
          our Service is used.
        </p>
        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>

        <h3>Email</h3>
        <p>
          We may use third-party Service Providers to deliver email messages you
          request.
        </p>
        <ul>
          <li>
            <p>
              <strong>Mailerlite</strong>
            </p>
            <p>
              Mailerlite is an email service provider. Learn more about what
              data they collect and how they use it through{' '}
              <a href="https://www.mailerlite.com/legal">
                their legal information page
              </a>
              .
            </p>
          </li>
        </ul>

        <h2>Links To Other Sites</h2>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>

        <h2>Children's Privacy</h2>
        <p>
          Our Service does not address anyone under the age of 18 ("Children").
        </p>
        <p>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Children has provided us with Personal Data,
          please contact us. If we become aware that we have collected Personal
          Data from children without verification of parental consent, we take
          steps to remove that information from our servers.
        </p>

        <h2>Changes To This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <ul>
          <li>By email: devon@raddevon.com</li>
        </ul>
      </Article>
    </Layout>
  );
};
export default PrivacyPolicy;
